<template>
	<div class="maxBox">
		<h1>注册服务协议</h1>
		<div class="textDes">
			<!-- <p>注册服务协议（Registered service agreement）</p> -->
			<p>《游戏许可及服务协议》（以下简称“本协议”）由您与游戏服务提供方即长沙奇葩斗斗网络科技有限公司共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款（以下称“免责条款”）、对用户权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款，以及开通或使用某项服务的单独协议。前述免责、限制及争议解决方式和管辖条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，包括但不限于本协议第二条、第三条、第四条、第六条、第九条等相关条款，您对该等条款的确认将可能导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前或在使用游戏服务之前再次阅读前述条款。双方确认前述条款并非属于《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利的”的条款，并同意该条款的合法性及有效性。 除非您已阅读并接受本协议所有条款，否则您无权使用游戏服务。如果您对本协议或游戏服务有意见或建议，可与客户服务部门联系，我们会给予您必要的帮助。您点击同意、接受或下一步，或您注册、使用游戏服务均视为您已阅读并同意签署本协议。 如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。如您为未成年人法定监护人，希望合理设定孩子娱乐时间，培养孩子健康游戏习惯。
			</p><p>一、【定义】
			</p><p>1.1 本协议：指本协议正文、《服务协议》、《游戏账号规则》、《隐私政策》、游戏规则及其修订版本。上述内容一经正式发布，即为本协议不可分割的组成部分。本协议同时还包括文化部根据《网络游戏管理暂行办法》（文化部令第49号）制定的《网络游戏服务格式化协议必备条款》。
			</p><p>1.2 游戏规则：指游戏服务提供方不时发布并修订的关于游戏的用户守则、玩家条例、游戏公告、提示及通知等内容。
			</p><p>1.3 游戏服务提供方：指向您提供游戏及其服务的长沙奇葩斗斗网络科技有限公司，在本协议中简称为“本公司”。
			</p><p>1.4 游戏：指由本公司负责运营的游戏的统称，包括计算机客户端游戏、网页游戏、HTML5游戏（H5游戏）、移动终端游戏、电视端游戏以及其他形式的游戏；游戏可能以软件形式提供，这种情况下，游戏还包括该相关软件及相关文档。
			</p><p>1.5 游戏服务：指向您提供的与游戏相关的各项在线运营服务。
			</p><p>1.6 您：又称“玩家”或“用户”，指被授权使用游戏及其服务的自然人。
			</p><p>1.7 游戏数据：指您在使用游戏过程中产生的被服务器记录的各种数据，包括但不限于角色数据、虚拟物品数据、行为日志、购买日志等等数据。
			</p><p>1.8 我们：游戏服务提供方即长沙奇葩斗斗网络科技有限公司
			</p><p>二、【游戏账号】
			</p><p>2.1 您如果需要使用和享受游戏，则您需要自主创建一个游戏账号作为游戏账号，并按照文化部《网络游戏管理暂行办法》及《网络游戏服务格式化协议必备条款》的相关要求，登录实名注册系统并进行实名注册。您对该游戏账号的申请、使用等行为应符合不时修订并公布的《服务协议》的规范。 您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。否则，有权终止为您提供游戏服务，并有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏帐号全部或部分功能、删除游戏账号及游戏数据、删除相关信息、游戏账号封禁（以下有时简称“封号”）直至注销的处理措施（为描述方便，以下有时也将该等处理措施称为“处罚”），因此造成的一切后果由您自行承担。 若您不进行实名注册的，或您提供的注册信息不完整的，则您可能无法使用游戏或在使用游戏过程中会受到相应限制。
			</p><p>2.2 如您使用认可的第三方帐号作为游戏账号使用和享受游戏的，您还应遵守有关该第三方帐号的协议、规则，且因该第三方帐号产生的相关问题包括但不限于被盗等，您应自行联系该第三方进行解决，本公司可视情况提供相应的协助。
			</p><p>2.3 您充分理解并同意：为判断或核实您提供的相关实名注册信息是否真实或有效，本公司有权将您提供的实名注册信息提供给第三方进行整理、保存及比对等处理。且会按照国家相关要求将您的实名注册信息运用于防沉迷系统之中，即可能会根据您的实名注册信息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对您的游戏账号予以防沉迷限制。
			</p><p>2.4 您充分理解并同意，本公司有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。 若您发现有他人冒用或盗用您的游戏账号及密码、或任何其他未经您合法授权使用的情形时，应立即以本公司要求的有效方式通知并告知需采取的措施。您通知时，应提供与您注册身份信息相一致的个人有效身份信息，收到您的有效请求并核实身份后，本公司会根据您的要求或结合具体情况采取相应措施（包括但不限于暂停该账号的登录和使用等），因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，本公司有权拒绝您的请求，因此造成您损失的，由您自行承担。
			</p><p>2.5 您充分理解并同意，为高效利用服务器资源，如果您3年内未使用游戏账号登录游戏，有权在提前通知的情况下，对该账号及其账号下的游戏数据及相关信息采取删除等处置措施。
			</p><p>2.6 您理解并同意，您不得将游戏账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用等方式提供给他人作包括但不限于直播、录制、代打代练等商业性使用。否则，因此产生任何法律后果及责任均由您自行承担，且本公司有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏帐号全部或部分功能、删除游戏账号及游戏数据及其他相关信息、封号直至注销的处理措施，因此造成的一切后果由您自行承担。
			</p><p>三、【用户信息收集、使用及保护】
			</p><p>3.1 您同意并授权为履行本协议之目的收集您的用户信息，这些信息包括您在实名注册系统中注册的信息、您游戏账号下的游戏数据以及其他您在使用游戏服务的过程中向本公司提供或基于安全、用户体验优化等考虑而需收集的信息，本公司对您的用户信息的收集将遵循本协议及相关法律的规定。
			</p><p>3.2 您充分理解并同意：本公司或其合作的第三方可以根据您的用户信息，通过短信、电话、邮件等各种方式向您提供关于游戏的活动信息、推广信息等各类信息。
			</p><p>3.3 您理解并同意：为了更好地向您提供游戏服务，改善游戏体验，本公司可对您游戏账号中的昵称、头像以及在游戏中的相关操作信息、游戏信息等信息（以下称“该等信息”。该等信息具体包括但不限于您的登录状态、对战信息/状态、成就信息等）进行使用，并可向您本人或其他用户或好友展示该等信息。
			</p><p>3.4 您应对通过游戏及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
			</p><p>3.5 保护用户信息及隐私是的一项基本原则。除本协议另有规定外，游戏服务对用户信息收集、使用及保护等将遵循统一公布的相关隐私政策。
			</p><p>四、【游戏服务】
			</p><p>4.1 在您遵守本协议及相关法律法规的前提下，我们给予您一项个人的、不可转让及非排他性的许可，以使用游戏服务。您仅可为非商业目的使用游戏服务，包括：
			</p><p>（1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏游戏；
			</p><p>（2）创建游戏角色，设置网名，查阅游戏规则、用户个人资料、游戏对局结果，开设游戏房间、设置游戏参数，在游戏中购买、使用游戏道具、游戏装备、游戏币等，使用聊天功能、社交分享功能；
			</p><p>（3）使用游戏支持并允许的其他某一项或几项功能。
			</p><p>4.2 您在使用游戏服务过程中不得未经许可以任何方式录制、直播或向他人传播游戏内容，包括但不限于不得利用任何第三方软件进行网络直播、传播等。
			</p><p>4.3 在游戏以软件形式提供的情况下，您在使用游戏及游戏服务时还应符合本协议第五条关于软件许可的规定。
			</p><p>4.4 本条及本协议其他条款未明示授权的其他一切权利仍由保留，您在行使这些权利时须另外取得的书面许可。
			</p><p>4.5 如果您违反本协议约定的，本公司有权采取相应的措施进行处理，该措施包括但不限于：不经通知随时对相关内容进行删除，并视行为情节对违规游戏账号处以包括但不限于警告、限制或禁止使用全部或部分功能、游戏账号封禁直至注销的处罚，并公告处理结果，要求您赔偿因您从事违约行为而给本公司造成的损失等。
			</p><p>4.6 您充分理解并同意，本公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
			</p><p>4.7 您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；因此遭受损失的，您也应当一并赔偿。
			</p><p>4.8 您充分理解并同意：游戏道具、游戏装备、游戏币等是游戏服务的一部分，在此许可您依本协议而获得其使用权。您购买、使用游戏道具、游戏装备、游戏币等应遵循本协议、游戏具体规则的要求；同时，游戏道具、游戏装备、游戏币等可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于的原因外，一旦有效期届满，将会自动失效。 您充分理解并同意：为更好地向用户提供游戏服务，我们有权对游戏中的任何内容或构成元素等作出调整、更新或优化（包括但不限于您购买或正在使用的角色、游戏装备及其他游戏道具的美术设计、性能及相关数值设置等作出调整、更新或优化等）。且如做出相应调整、更新或优化的，您同意不会因此追究的任何法律责任。
			</p><p>4.9 您充分理解并同意：为保障您游戏账号安全，为营造公平、健康及安全的游戏环境，在您使用游戏服务的过程中，在不违反相关法律规定情况下，我们可以通过技术手段了解您终端设备的随机存储内存以及与游戏同时运行的相关程序。一经发现有任何未经授权的、危害游戏服务正常运营的相关程序，我们可以收集所有与此有关的信息并采取合理措施予以打击。
			</p><p>4.10 您充分理解并同意：为了保证您及其他用户的游戏体验，我们有权转移或者清除游戏服务器上存储的一些过往的游戏数据。
			</p><p>4.11 我们将按照相关法律法规和本协议的规定，采取切实有效的措施保护未成年人在使用游戏服务过程中的合法权益，包括可能采取技术措施、禁止未成年人接触不适宜的游戏或者游戏功能、限制未成年人的游戏时间、预防未成年人沉迷网络。作为游戏规则的一部分，还将在适当位置发布游戏用户指引和警示说明，包括游戏内容介绍、正确使用游戏的方法以及防止危害发生的方法。所有未成年人用户都应在法定监护人的指导下仔细阅读并遵照执行这些指引和说明；其他玩家在使用游戏服务的过程中应避免发布、产生任何有损未成年人身心健康的内容，共同营造健康游戏环境。
			</p><p>4.12 如果您未满18周岁的，为保障您的合法权益，我们有权依据国家有关法律法规及政策规定、本协议其他条款规定、游戏运营策略或根据您法定监护人的合理要求采取以下一种或多种措施：
			</p><p>（1）将与您游戏相关的信息（包括但不限于您游戏帐号的登录信息、充值流水信息等）提供给您的法定监护人，使得您法定监护人可及时或同步了解您游戏情况；
			</p><p>（2）限制您游戏账号的消费额度；
			</p><p>（3）采取技术措施屏蔽某些游戏或游戏的某些功能，或限定您游戏时间或游戏时长；
			</p><p>（4）注销或删除您游戏账号及游戏数据等相关信息；
			</p><p>（5）您法定监护人要求采取的，或认为可采取的其他合理措施，以限制或禁止您使用游戏。
			</p><p>4.13 我们向用户提供游戏服务本身属于商业行为，用户有权自主决定是否根据自行确定的收费项目（包括但不限于购买游戏内的虚拟道具的使用权以及接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。 您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求进行赔偿或补偿。
			</p><p>4.14 在任何情况下，不对因不可抗力导致的您在使用游戏服务过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争等不可预测、不可避免且不可克服的事件。
			</p><p>4.15 游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的游戏角色、游戏道具、游戏装备及游戏币等账号数据发生异常。在数据异常的原因未得到查明前，我们有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所致，有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），且无须向您承担任何责任。
			</p><p>4.16 我们未授权您从任何第三方通过购买、接受赠与或者其他的方式获得游戏账号、游戏道具、游戏装备、游戏币等，不对第三方交易的行为负责，并且不受理因任何第三方交易发生纠纷而带来的申诉。
			</p><p>4.17 您充分理解到：不同操作系统之间存在不互通的客观情况，该客观情况并非造成，由此可能导致您在某一操作系统中的充值和游戏数据不能顺利转移到另一操作系统中。由于您在不同系统进行切换造成的充值损失和游戏数据丢失风险应由您自行承担，我们对此不承担任何责任。
			</p><p>4.18 您充分理解到：游戏中可能会设置强制对战区域或玩法，如果您不同意强制对战，请您不要进入该游戏或游戏区域；您的进入，将被视为同意该玩法并接受相应后果。
			</p><p>4.19 我们自行决定终止运营游戏时或游戏因其他任何原因终止运营时，会按照文化部有关网络游戏终止运营的相关规定处理游戏终止运营相关事宜，以保障用户合法权益。
			</p><p>五、【软件许可】
			</p><p>5.1 使用游戏服务可能需要下载并安装相关软件，您可以直接从的相关网站上获取该软件，也可以从得到授权的第三方获取。如果您从未经授权的第三方获取游戏或与游戏名称相同的游戏，将视为您未获得授权，无法保证该游戏能够正常使用，并对因此给您造成的损失不予负责。
			</p><p>5.2 我们可能为不同的终端设备或操作系统开发了不同的软件版本，包括但不限于windows、ios、android、windows phone、symbian、blackberry等多个应用版本，您应当根据实际情况选择下载合适的版本进行安装，下载安装程序后，您需要按照该程序提示的步骤正确安装。
			</p><p>5.3 若游戏以软件形式提供，给予您一项个人的、不可转让及非排他性的许可。您仅可为非商业目的在单一台终端设备上下载、安装、登录、使用该游戏。
			</p><p>5.4 为提供更加优质、安全的服务，在软件安装时可能推荐您安装其他软件，您可以选择安装或不安装。
			</p><p>5.5 如果您不再需要使用该软件或者需要安装新版，可以自行卸载。如果您愿意帮助改进产品服务，请告知卸载的原因。
			</p><p>5.6 为了保证游戏服务的安全性和功能的一致性，我们有权对软件进行更新，或者对软件的部分功能效果进行改变或限制。
			</p><p>5.7 软件新版本发布后，旧版本的软件可能无法使用。不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
			</p><p>六、【用户行为规范】
			</p><p>6.1 您充分了解并同意，您必须为自己游戏账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。
			</p><p>6.2 您除了可以按照本协议的约定使用游戏服务之外，不得进行任何侵犯游戏的知识产权的行为，或者进行其他的有损于或其他第三方合法权益的行为。
			</p><p>6.3 您在使用游戏或游戏服务时须遵守法律法规，不得利用游戏或游戏服务从事违法违规行为，包括但不限于以下行为：
			</p><p>（一）违反宪法确定的基本原则的；
			</p><p>（二）危害国家统一、主权和领土完整的；
			</p><p>（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
			</p><p>（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
			</p><p>（五）宣扬邪教、迷信的；
			</p><p>（六）散布谣言，扰乱社会秩序，破坏社会稳定的；
			</p><p>（七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；
			</p><p>（八）侮辱、诽谤他人，侵害他人合法权益的；
			</p><p>（九）违背社会公德的；
			</p><p>（十）有法律、行政法规和国家规定禁止的其他内容的。
			</p><p>6.4 除非法律允许或书面许可，您不得从事下列行为：
			</p><p>（1）删除游戏软件及其副本上关于著作权的信息；
			</p><p>（2）对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；
			</p><p>（3）对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；
			</p><p>（4）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；
			</p><p>（5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；
			</p><p>（6）通过非开发、授权的第三方软件、插件、外挂、系统，使用游戏及游戏服务，或制作、发布、传播非开发、授权的第三方软件、插件、外挂、系统；
			</p><p>（7）对游戏中拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
			</p><p>（8）建立有关游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与游戏服务完全相同或者类似的服务；
			</p><p>（9）将游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；
			</p><p>（10）使用、修改或遮盖游戏的名称、商标或其它知识产权；
			</p><p>（11）其他未经明示授权的行为。
			</p><p>6.5 您在使用游戏服务过程中有如下任何行为的，我们有权视情节严重程度，依据本协议及相关游戏规则的规定，对您做出暂时或永久性地禁止登录（即封号）、删除游戏账号及游戏数据、删除相关信息等处理措施，情节严重的将移交有关行政管理机关给予行政处罚，或者追究您的刑事责任：
			</p><p>（1）以某种方式暗示或伪称内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；
			</p><p>（2）在游戏中使用非法或不当词语、字符等，包括用于角色命名；
			</p><p>（3）以任何方式破坏游戏或影响游戏服务的正常进行；
			</p><p>（4）各种非法外挂行为；
			</p><p>（5）传播非法言论或不当信息；
			</p><p>（6）盗取他人游戏账号、游戏物品；
			</p><p>（7）私自进行游戏账号交易；
			</p><p>（8）私自进行游戏虚拟货币、游戏装备、游戏币及其他游戏道具等交易；
			</p><p>（9）违反本协议任何约定的；
			</p><p>（10）其他在行业内被广泛认可的不当行为，无论是否已经被本协议或游戏规则明确列明。
			</p><p>您知悉并同意，由于外挂具有隐蔽性或用完后即消失等特点，本公司有权根据您的游戏数据和表现异常判断您有无使用非法外挂等行为。
			</p><p>6.6 您知悉并同意，如依据本协议对您的游戏账号采取封号处理措施的，具体封号期间由本公司根据您违规行为情节而定。
			</p><p>您知悉并同意：（1）在封号期间，您游戏账号中的游戏虚拟货币、游戏装备、游戏币及其他游戏道具可能都将无法使用；（2）如前述游戏虚拟货币、游戏装备、游戏币及其他游戏道具存在一定有效期，该有效期可能会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟货币、游戏装备、游戏币及其他游戏道具。据此，您也同意不会因发生前述第（1）和（或）第（2）点规定的情形而追究任何法律责任。
			</p><p>七、【知识产权】
			</p><p>7.1 我们是游戏的知识产权权利人。游戏（包括游戏整体及游戏涉及的所有内容、组成部分或构成要素 ）的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与游戏相关的所有信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，享有上述知识产权和合法权益，但相关权利人依照法律规定应享有的权利除外。未经事先书面同意，您不得以任何方式将游戏（包括游戏整体及游戏涉及的所有内容、组成部分或构成要素 ）进行商业性使用。
			</p><p>7.2 尽管本协议有其他规定，您在使用游戏服务中产生的游戏数据的所有权和知识产权归我们所有，我们有权保存、处置该游戏数据。其中，对用户购买游戏虚拟货币的购买记录的保存期限将遵守文化部《网络游戏管理暂行办法》有关规定。对其他游戏数据的保存期限由我们自行决定，但国家法律法规另有规定的从其规定。
			</p><p>7.3 游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在游戏中使用该等知识产权有要求的，将以适当方式向您告知该要求，您应当一并遵守。
			</p><p>八、【遵守当地法律监管】
			</p><p>8.1 您在使用游戏服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
			</p><p>8.2 您应避免因使用游戏服务而使卷入政治和公共事件，否则我们有权暂停或终止对您的服务。
			</p><p>九、【管辖与法律适用】
			</p><p>9.1 本协议签订地为中华人民共和国湖南省长沙市开福区。
			</p><p>9.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
			</p><p>9.3 若您和我们之间因本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院管辖。
			</p><p>9.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
			</p><p>9.5 本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。
			</p><p>十、【其他】
			</p><p>10.1 我们有权在必要时变更本协议条款，您可以在游戏的相关页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用游戏服务，即视为您已接受变更后的协议。
			</p><p>10.2 根据国家新闻出版总署关于健康游戏的忠告，提醒您：抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身
			</p><p>本协议签署地：湖南省长沙市开福区
			</p>
		</div>
	</div>
</template>

<script setup>
	
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
	p{
		text-indent: 2em;
		font-size: 14px!important;
		line-height: 24px!important;
		color: #606266 !important;
		margin: 16px 0 0 0!important;
	}
	a{color: #000000;}
	h2{text-align: center;}
	h4{margin-top: 16px;}
</style>
